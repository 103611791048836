/* eslint no-template-curly-in-string: 0*/
const awsConfig = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://fcsqv7rv8f.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  gqlGateway: {
    REGION: "eu-central-1",
    URL: "https://fcsqv7rv8f.execute-api.eu-central-1.amazonaws.com/prod/graphql",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_sFRfZm0gS",
    APP_CLIENT_ID: "7d9vo0fht28ms2bti5s29dertr",
    IDENTITY_POOL_ID: "eu-central-1:1927b2ae-b214-4153-89af-4ee2eb9474e1",
  },
  i18n: {
    host: "locales.digital.beta.canei.io",
  },
  assets: {
    host: "assets.digital.beta.canei.io",
  },
  registration: {
    FORM_POST: "https://support.digital.beta.canei.io/v1/forms",
    NEXT_PAGE: "https://app.quick.beta.canei.io",
    TO_REFERRER: "https://canei.ag/register-success/",
  },
  payment: {
    publicKey: "pk_test_51Kb2CFJafEP8n6QGsNJNLK9iljof2ilJpV8XPLHv9Q5nRP4vtaY8fvpevUs2wGAb83ZgiREDXqucNGsrm2HthSTf00Jy8h3kW8",
  },
  defaults: {
    SAMPLE_SUSA_URL: "https://datastore.digital.beta.canei.io/records/files/Muster-SuSa.pdf",
    SAMPLE_SUSA_S3: "s3://datastore.digital.beta.canei.io/records/files/Muster-SuSa.pdf",
  },
};

export default awsConfig;
